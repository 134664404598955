module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aurinia Alliance Patient Support","short_name":"Aurinia Alliance Patient Support","start_url":"/","background_color":"#fff","theme_color":"#00597d","display":"standalone","icon":"static/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.auriniaalliance.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Hoefler Text Black"],"urls":["/fonts/fonts.css"]},"google":{"families":["Montserrat","Montserrat:light","Montserrat:regular","Montserrat:regular italic","Montserrat:medium","Montserrat:medium italic","Montserrat:semi-bold","Montserrat:semi-bold italic","Montserrat:bold","Montserrat:bold italic","Montserrat:extra-bold","Montserrat:extra-bold italic","Montserrat:black","Montserrat:black italic"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
